import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit, AfterViewInit {

    // displa: boolean = false;
  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
  ngAfterViewInit(){
    const balao = document.getElementById('balao-chat');
    balao?.classList.add('show')
    setTimeout( () => {
      balao?.classList.remove('show')
      balao?.classList.add('hide')
      
      
    }, 12000)
  }


}
