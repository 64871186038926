import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TituloAbaService {

  constructor(private titleService: Title, private meta:Meta) { }

  private setCanonicalURL(url: string) {
    const linkElement: HTMLLinkElement | null = document.querySelector('link[rel="canonical"]');
    
    if (linkElement) {
      // Se a tag já existe, atualize o href
      linkElement.setAttribute('href', url);
    } else {
      // Se a tag não existe, crie uma nova
      const link: HTMLLinkElement = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', url);
      document.head.appendChild(link);
    }
  }

  public setTitle(newTitle: string) {

    const content = `${newTitle} | azulzinha da CAIXA`
    const uri = `https://www.azulzinhadacaixa.com.br${location.pathname}`

    this.titleService.setTitle(content);
    this.setCanonicalURL(uri);

    this.meta.updateTag(
      {
        property: "og:title",
        content: content
      }
    )
    this.meta.updateTag(
      {
        name: "twitter:title",
        content: content
      }
    )
    
  }
  public setMeta(name: string, content: string) {
    console.log(name, content)
    const urlimg = 'https://azulzinhadacaixa.com.br/assets/img/group-maquinas.png'
    const uri = `https://www.azulzinhadacaixa.com.br${location.pathname}`
    
    this.meta.updateTag(
      { 
        property: 'og:type', 
        content: 'website' 
      }
    );
    this.meta.updateTag(
      { 
        property: 'og:url', 
        content: uri
      }
    );
    this.meta.updateTag(
      { 
        name: 'twitter:card', 
        content: 'summary_large_image' 
      }
    );
    this.meta.updateTag(
      { 
        name: name, 
        content: content 
      }
    );
    if(name === 'description'){

      this.meta.updateTag(
        { 
          property: 'og:description', 
          content: content 
        }
      );
      this.meta.updateTag(
        { 
          name: 'twitter:description', 
          content: content 
        }
      );
      this.meta.updateTag(
        { 
          property: 'image', 
          content: urlimg
        }
      );
      this.meta.updateTag(
        { 
          property: 'og:image', 
          content: urlimg
        }
      );
   
      this.meta.updateTag(
        { 
          name: 'twitter:image', 
          content: urlimg
        }
      );
  
      return
    }
    if(name === 'image'){
      
      this.meta.updateTag(
        { 
          property: 'image', 
          content: content
        }
      );
      this.meta.updateTag(
        { 
          property: 'og:image', 
          content: content
        }
      );
      this.meta.updateTag(
        { 
          name: 'twitter:image', 
          content: content
        }
      );
    }
    if(name === 'imageAlt'){
      
      this.meta.updateTag(
        { 
          name: 'twitter:image:alt', 
          content: content
        }
      );
      this.meta.updateTag(
        { 
          property: 'og:image:alt', 
          content: content
        }
      );
    }

  }
}
