<footer class="">
  <div class="footer2">
    <div class="area-container2">
      <div class="container-links">
        <div class="area-link mobile">
          <div class="title2">SOLUÇÕES</div>
          <a class="link" href="/azulzinha-caixa-tem">CAIXA Tem</a>
          <a class="link" routerLink="/maquininhas">Maquininhas</a>
          <a class="link" routerLink="/vendas-online">Vendas Online</a>
          <div class="title2 mt-4">SERVIÇOS</div>
          <a class="link" routerLink="/antecipacao-de-vendas">Antecipação de vendas</a>
          <a class="link" target="_blank" href="https://www.azulzinhapay.com.br/">azulzinha pay</a>
          <a class="link" routerLink="/bandeiras-vouchers"
            >Bandeiras e Vouchers</a
          >
          <a class="link" routerLink="/crediario-azulzinha">Crediário</a>
          <a class="link" routerLink="/lotericas">Lotéricas</a>
          <a class="link" routerLink="/pagamento-parcial">Pagamento Parcial</a>
          <a class="link" routerLink="/pagamentos-whatsapp"
            >Pagamentos por WhatsApp</a
          >
          <a class="link" routerLink="/pix-azulzinha">Pix</a>
          <a class="link" routerLink="/recarga-de-celulares">Recarga</a>
        </div>
        <div class="area-link mobile">
          <div class="title2">GESTÃO DO SEU NEGÓCIO</div>
          <a class="link" routerLink="/app">App da azulzinha</a>
          <a class="link" routerLink="/portal">Portal da azulzinha</a>
          <div class="title2 mt-4">
            <a style="color: #005faf" routerLink="/blog">BLOG</a>
          </div>
          <div class="title2 mt-4">SUPORTE</div>
          <a class="link" href="/ajuda">Ajuda</a>
          <a class="link" routerLink="/aviso-de-cookies">Aviso de Cookies</a>
          <a
            class="link"
            href="{{
              env.origin
            }}/midias/Fiserv_Contrato_de_Adesao_e_Credenciamento_4_RTD_SP.pdf"
            target="_blank"
            download
            >Contrato de Credenciamento azulzinha</a
          >
          <a
            class="link"
            href="{{
              env.origin
            }}/midias/termo-condicoes-complementares-CAIXA_Tem.pdf"
            target="_blank"
            download
          >
            Contrato de Credenciamento CAIXA Tem</a
          >
          <a class="link" routerLink="/termo-lgpd"> LGPD</a>
          <a class="link" routerLink="/politica-de-privacidade"
            >Política de Privacidade</a
          >
          <a class="link" routerLink="/maquinas-e-taxas">Taxas</a>
        </div>

        <div class="area-link mobile">
          <div class="d-flex align-items-center container-avatar md-d-none">
            <img src="/assets/img/avatar-zuli-footer.png" alt="">
            <div>
              <p class="font-blue font-base">Fale com a Zuli pelo WhatsApp</p>
              <div class="container-number d-flex align-items-center">
                <img src="/assets/img/icons/wpp-negativo.svg" alt="" />
                <a class="number"  href="https://api.whatsapp.com/send/?phone=5511941473336" target="_blank">(11) 94147-3336</a>
              </div>
            </div>
          </div>
          <div class="title2">CENTRAL DE RELACIONAMENTO</div>
  
          <a href="tel:+551130035365" style="margin: 0" class="numero"
            ><strong>3003 5365</strong></a
          >
          <a style="margin: 0" class="link mb-2">Capital e Grande São Paulo </a>
          <a href="tel:08007295365" class="numero mb-0"><strong>0800 729 5365</strong> </a>
          <a style="margin: 0" class="link">Demais localidades </a>
          <p class="text-horas">
            Segunda a Domingo das 08h00 às 22h00 (exceto feriados)
          </p>
          <div>
            <p class="mt-4 mensage-chat title2">
              AZULZINHA NO CAIXA TEM
            </p>
            <p style="font-size: 14px;">Atendimento somente via chatbot pelo App CAIXA Tem</p>
          </div>
          <div class="texto-mobile">
            <div class="title2 mt-4">OUVIDORIA</div>
            <a href="tel:0800 727 4655" class="numero"><strong>0800 727 4655</strong> </a>
            <p class="text-horas">
              Segunda a Sexta das 09h00 às 18h00 (exceto feriados)
            </p>
        
            <div class="midias-sociais">
              <a target="_blank" href="https://www.instagram.com/azulzinhadacaixa/"><img src="/assets/img/icons/icon-ig.svg" alt=""></a>
              <a target="_blank" href="https://www.facebook.com/azulzinhadacaixa "><img src="/assets/img/icons/icon-fb.svg" alt=""></a>
              <a target="_blank" href="https://www.linkedin.com/company/azulzinhadacaixa "><img src="/assets/img/icons/icon-lk.svg" alt=""></a>
              <a target="_blank" href="https://www.youtube.com/@azulzinhadaCAIXA "><img src="/assets/img/icons/icon-yt.svg" alt=""></a>
            </div>
          </div>
        </div>
        <div class="d-none area-link mobile">
          <div class="d-flex align-items-center container-avatar">
            <img src="/assets/img/avatar-zuli-footer.png" alt="">
            <div>
              <p class="font-blue font-base">Fale com a Zuli pelo WhatsApp</p>
              <div class="container-number d-flex align-items-center">
                <img src="/assets/img/icons/wpp-negativo.svg" alt="" />
                <a class="number"  href="https://api.whatsapp.com/send/?phone=5511941473336" target="_blank">(11) 94147-3336</a>
              </div>
            </div>
          </div>
          <div class="title2">OUVIDORIA</div>
          <a href="tel:0800 727 4655" class="numero"><strong>0800 727 4655</strong> </a>
          <p class="text-horas">
            Segunda a Sexta das 09h00 às 18h00 (exceto feriados)
          </p>
    
          <div class="midias-sociais mt-4">
            <a target="_blank" href="https://www.instagram.com/azulzinhadacaixa/"><img src="/assets/img/icons/icon-ig.svg" alt=""></a>
            <a target="_blank" href="https://www.facebook.com/azulzinhadacaixa "><img src="/assets/img/icons/icon-fb.svg" alt=""></a>
            <a target="_blank" href="https://www.linkedin.com/company/azulzinhadacaixa "><img src="/assets/img/icons/icon-lk.svg" alt=""></a>
            <a target="_blank" href="https://www.youtube.com/@azulzinhadaCAIXA "><img src="/assets/img/icons/icon-yt.svg" alt=""></a>
          </div>
        </div>
   
      </div>
      <div class="area-logo">
        <div class="logo-caixa">
          <img
            class="img-caixa"
            [lazyLoad]="'assets/img/caixa-cartoes-logo.png'"
            alt=""
          />
        </div>
        <div class="logo-fiserv">
          <img
            class="img-fiserv"
            [lazyLoad]="'assets/img/logo-footer-fiserv.png'"
            alt=""
          />
        </div>
      </div>
      <div class="text-center mt-5 md-margin-bottom">
        <p class="link">
          © 2024 Fiserv do Brasil Instituição de Pagamento Ltda. Todos os
          direitos reservados.
        </p>
        <p class="link tel-sac">SAC Fiserv (24h): <a href="tel:08007706207">0800 770 6207</a></p>
      </div>
    </div>
  </div>
</footer>
