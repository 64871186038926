import { Component, OnInit } from '@angular/core';
import { TituloAbaService } from '../titulo-aba/titulo-aba.service';

@Component({
  selector: 'app-pagina-not-found',
  templateUrl: './pagina-not-found.component.html',
  styleUrls: ['./pagina-not-found.component.scss']
})
export class PaginaNotFoundComponent implements OnInit {

  constructor(private tituloAbaService: TituloAbaService) { }

  ngOnInit(): void {
    this.tituloAbaService.setTitle('Not Found');
  }

}
