<div class="chatbot">
  <div class="avatar">
    <a href="https://wa.me/5511941473336" target="_blank"
      ><img
        class="avatar-img"
        src="assets/img/zuli-flutuante.png"
        alt="Atendente virtual"
    /></a>
    <a target="_blank" href="https://api.whatsapp.com/send/?phone=5511941473336">
      <div id="balao-chat" class="chat">
        <div class="chat-message">
          <span class="container-message">
            <span>
              <p class="m-0 font-blue">
                <strong
                  >Dúvidas?
                  <span class="text-hidden">Fale com a Zuli</span></strong
                >
              </p>
            </span>
          </span>
        </div>
      </div>
    </a>
  </div>
</div>
