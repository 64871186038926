import {Component, OnInit} from '@angular/core';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "apl-web-caixa-pagamentos";
  constructor() {}
  meuVideo = document.querySelector("video");
  ngOnInit() {
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }
}

